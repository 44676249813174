// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestPreview_TestPreview__0LaKc {
  position: relative;
  overflow: hidden;
}
.TestPreview_TestPreview__Background__\\+vXOn {
  position: absolute;
  z-index: -2;
  width: 700px;
  height: 1000px;
  background: linear-gradient(90deg, #DEE4E8 0%, #0174DE 100%);
  display: block;
  transform: rotate(45deg);
  top: 0px;
  right: -150px;
}
.TestPreview_TestPreview__ActionButton__1GVS1 {
  min-width: 350px !important;
  margin-bottom: 100px;
  margin-top: 30px;
}
.TestPreview_TestPreview__Cert__\\+kPm5 {
  width: 315px;
  height: 380px;
  margin-left: auto;
  background: #ccc;
  border-radius: 5px;
  overflow: hidden;
}
.TestPreview_TestPreview__Cert__\\+kPm5 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/TestPreview/TestPreview.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,4DAAA;EACA,cAAA;EACA,wBAAA;EACA,QAAA;EACA,aAAA;AAER;AAAI;EACI,2BAAA;EACA,oBAAA;EACA,gBAAA;AAER;AAAI;EACI,YAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAER;AADQ;EACI,iBAAA;EACA,WAAA;EACA,YAAA;AAGZ","sourcesContent":[".TestPreview{\n    position: relative;\n    overflow: hidden;\n    &__Background {\n        position: absolute;\n        z-index: -2;\n        width: 700px;\n        height: 1000px;\n        background: linear-gradient(90deg, #DEE4E8 0%, #0174DE 100%);\n        display: block;\n        transform: rotate(45deg);\n        top: -00px;\n        right: -150px;\n    }\n    &__ActionButton {\n        min-width: 350px !important;\n        margin-bottom: 100px;\n        margin-top: 30px;\n    }\n    &__Cert {\n        width: 315px;\n        height: 380px;\n        margin-left: auto;\n        background: #ccc;\n        border-radius: 5px;\n        overflow: hidden;\n        img {\n            object-fit: cover;\n            width: 100%;\n            height: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestPreview": `TestPreview_TestPreview__0LaKc`,
	"TestPreview__Background": `TestPreview_TestPreview__Background__+vXOn`,
	"TestPreview__ActionButton": `TestPreview_TestPreview__ActionButton__1GVS1`,
	"TestPreview__Cert": `TestPreview_TestPreview__Cert__+kPm5`
};
export default ___CSS_LOADER_EXPORT___;
