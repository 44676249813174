import axios from 'axios';
import Cookies from 'js-cookie';
import { env } from '../utils/env';

export const getTestStatus = async ({ testId, findBy }) => {
  try {
    const token = Cookies.get('jwt');
    const formdata = new FormData();
    formdata.append('testId', testId);
    formdata.append('token', token);
    formdata.append('findBy', findBy);

    let config = {
      method: 'post',
      url: `${env.API_CUSTOM_URL}/tests/get-status`,
      data: formdata,
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

// new api
export const getTestResult = async ({ testId }) => {
  try {
    const token = Cookies.get('jwt');
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/test/get-result/${testId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleTests = async ({ limit, offset, relations }) => {
  try {
    const token = Cookies.get('jwt');
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/test/get-all-single`,
      params: {
        limit: limit || 15,
        offset: offset || 0,
        relations: relations || [],
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const getOneTestById = async ({ testId, relations }) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/test/get/${testId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations: relations || [],
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const editTestResult = async ({
  test,
  material,
  course,
  result,
  status,
  questionResult,
  canGenerateCertificate,
}) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/test/edit-result`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        result,
        status,
        testSnapshot: test || {},
        materialSnapshot: material || {},
        courseSnapshot: course || {},
        questionAnswersSnapshot: questionResult || [],
        canGenerateCertificate,
      },
    };

    const resultQuery = await axios(config);
    return resultQuery.data;
  } catch (error) {
    throw error;
  }
};

export const setQuestionResult = async (data) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/test/set-question-result`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const resultQuery = await axios(config);
    return resultQuery.data;
  } catch (error) {
    throw error;
  }
};
