import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Avatar, Button, Stack } from '@mui/material';

import { URLS } from '../../utils/URLS';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { Link, useNavigate } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useSelector } from 'react-redux';
import { AlertsList } from '../AlertsList/AlertsList';
import { useCookies } from 'react-cookie';
import { useApi } from '../../hooks/useApi';
import { getMe } from '../../api/UserApi';
import { Helmet } from 'react-helmet';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const MainLayout = ({ children, hidden }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const getMeApi = useApi(getMe);
  const [customer, setCustomer] = useState({});

  const menu = [
    {
      url: URLS.DASHBOARD,
      icon: <GridViewOutlinedIcon />,
    },
    {
      url: URLS.ALL_COURSES,
      icon: <SchoolOutlinedIcon />,
    },
    hidden?.materials && {
      url: URLS.ALL_MATERIALS,
      icon: <SlowMotionVideoOutlinedIcon />,
    },
    hidden?.testS && {
      url: URLS.ALL_TESTS,
      icon: <CheckBoxOutlinedIcon />,
    },
  ];
  const navigate = useNavigate();
  const alerts = useSelector((state) => state.alerts);
  useEffect(() => {
    if (!cookies.jwt) {
      navigate(URLS.LOGIN);
    } else {
      getMeApi
        .sendRequest(cookies.jwt)
        .then((result) => setCustomer(result))
        .catch((error) => {
          console.log(error);
          removeCookie('jwt');
          navigate('/login');
        });
    }
  }, [cookies]);
  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Образовательная платформа </title>

        <link
          rel="icon"
          href="https://static.tildacdn.com/tild6466-3666-4132-b032-343939313264/favicon.ico"
          type="image/x-icon"
        />
        <meta
          name="description"
          content="Комплексная поддержка экспортной деятельности малого и среднего бизнеса Петербурга. Широкий спектр услуг от консультаций экспертов до участия в зарубежных выставках. Бесплатно или на условиях софинансирования."
        />
        <meta property="og:url" content="https://track.spbexport.ru" />
        <meta property="og:title" content="Образовательный трек" />
        <meta
          property="og:description"
          content="Комплексная поддержка экспортной деятельности малого и среднего бизнеса Петербурга. Широкий спектр услуг от консультаций экспертов до участия в зарубежных выставках. Бесплатно или на условиях софинансирования."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://static.tildacdn.com/tild3731-6130-4733-b835-646537373732/-/resize/504x/6.jpeg"
        />
      </Helmet>
      <AlertsList alerts={alerts} />
      <CssBaseline />
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <Link to={'/dashboard'}>
            <Stack direction="row" spacing={2} alignItems="center">
              <div>
                <img src="/logo1.png" style={{ width: '90px' }} />
              </div>
              <div>
                <img src="/logo2.png" style={{ width: '110px' }} />
              </div>
              <div>
                <img src="/logo3.png" style={{ width: '80px' }} />
              </div>
              <div>
                <img src="/logo4.png" style={{ width: '70px' }} />
              </div>
              <div>
                <img src="/logo5.png" style={{ width: '70px' }} />
              </div>
            </Stack>
          </Link>
          <Button
            href="https://spbexport.ru/"
            target="blank"
            sx={{ ml: 'auto', borderRadius: '20px' }}
            startIcon={<LaunchOutlinedIcon />}
            variant="outlined"
          >
            Основной сайт
          </Button>
          <Stack
            direction="row"
            spacing={2}
            sx={{ ml: 2, height: '80px' }}
            alignItems="center"
          >
            <div style={{ textAlign: 'right', display: 'block' }}>
              <Typography sx={{ mb: 0, pb: 0 }}>
                {customer?.firstName && customer?.lastName
                  ? customer?.firstName + ' ' + customer?.lastName
                  : customer?.email}
              </Typography>
              <Typography
                variant="caption"
                sx={{ mt: '0px', pt: 0, display: 'block' }}
              >
                {customer?.company?.inn}
              </Typography>
            </div>
            <Avatar sx={{ background: '#0166c4' }} variant="rounded">
              {customer?.firstName && customer?.lastName
                ? customer?.firstName[0] + customer?.lastName[0]
                : customer?.email?.slice(0, 2)}
            </Avatar>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent">
        <List sx={{ mt: 13 }}>
          {menu.map((item, index) =>
            item ? (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => navigate(item.url)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,

                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,

                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ) : (
              ''
            ),
          )}
        </List>
        <Divider />
        <List>
          <ListItem
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => navigate(URLS.PROFILE)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                }}
              >
                <AccountCircleOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => removeCookie('jwt')}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                }}
              >
                <LogoutOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        {children}
      </Box>
    </Box>
  );
};
