// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MaterialItem_MaterialItem__EMFN- {
  width: 275px;
  position: relative;
}
.MaterialItem_MaterialItem__Img__uiBqe {
  width: 100%;
  height: 155px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
}
.MaterialItem_MaterialItem__Img__uiBqe img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.MaterialItem_MaterialItem__Img__Placeholder__30tqH {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.2s;
  top: 0;
  left: 0;
}
.MaterialItem_MaterialItem__Img__Placeholder__30tqH:hover {
  opacity: 1;
  transition: 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/components/MaterialItem/MaterialItem.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uCAAA;EACA,eAAA;EACA,kBAAA;AAER;AADQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AAGZ;AADQ;EACI,kBAAA;EACA,8BAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,gBAAA;EACA,MAAA;EACA,OAAA;AAGZ;AAFY;EACI,UAAA;EACA,gBAAA;AAIhB","sourcesContent":[".MaterialItem{\n    width: 275px;\n    position: relative;\n    &__Img{\n        width: 100%;\n        height: 155px;\n        margin-bottom: 10px;\n        border-radius: 10px;\n        overflow: hidden;\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n        cursor: pointer;\n        position: relative;\n        img {\n            width: 100%;\n            height: 100%;\n            object-fit: contain;\n        }\n        &__Placeholder {\n            position: absolute;\n            background: rgba(0, 0, 0, 0.8);\n            z-index: 1;\n            width: 100%;\n            height: 100%;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            opacity: 0;\n            transition: 0.2s;\n            top: 0;\n            left: 0;\n            &:hover {\n                opacity: 1;\n                transition: 0.2s;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MaterialItem": `MaterialItem_MaterialItem__EMFN-`,
	"MaterialItem__Img": `MaterialItem_MaterialItem__Img__uiBqe`,
	"MaterialItem__Img__Placeholder": `MaterialItem_MaterialItem__Img__Placeholder__30tqH`
};
export default ___CSS_LOADER_EXPORT___;
