import React from 'react';

import styles from './TestPreview.module.scss';

import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import { MainLayout } from '../Layout/MainLayout';
import { DefaultSidebar } from '../DefaultSidebar/DefaultSidebar';
import { useSettings } from '../../hooks/useSettings';
import { URLS } from '../../utils/URLS';

export const TestPreview = ({ title, description, actionText, actionUrl }) => {
  const navigate = useNavigate();
  const settings = useSettings();
  return (
    <MainLayout>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3 }}>
            <Link underline="hover" color="inherit" href={URLS?.DASHBOARD}>
              Главная
            </Link>

            <Typography color="text.primary">{title}</Typography>
          </Breadcrumbs>
          <Typography as="h1" variant="h5" sx={{ mt: 5 }}>
            {title}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
          <Button
            sx={{ mt: 7 }}
            color="primary"
            variant="contained"
            className={styles.TestPreview__ActionButton}
            onClick={() => navigate(actionUrl)}
          >
            {actionText}
          </Button>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4} sx={{ pt: 5 }}>
          <DefaultSidebar settings={settings} />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
