import React, { useEffect, useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Sticky, StickyContainer } from 'react-sticky';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { CertificateFile } from '../CertificateFile/CertificateFile';
import { useApi } from '../../hooks/useApi';
import { getCertificate } from '../../api/UserApi';
import { PDFDownloadLink } from '@react-pdf/renderer';

export const DefaultSidebar = ({ settings }) => {
  const [certificate, setCertificate] = useState({});
  const getCertificateApi = useApi(getCertificate);
  useEffect(() => {
    getCertificateApi.sendRequest().then((result) => setCertificate(result));
  }, []);
  return (
    <StickyContainer style={{ height: '100%' }}>
      <Sticky>
        {({ style, distanceFromTop }) => (
          <div style={style}>
            <Card
              variant="outlined"
              sx={{
                background: '#0166c4',
                color: '#fff',
                mt: distanceFromTop < 0 ? '110px' : 0,
                transition: '0.3s',
              }}
            >
              <CardContent>
                <Stack direction="row" spacing={3}>
                  <img
                    src="/cert-preview.png"
                    style={{
                      width: '85px',
                      height: '85px',
                      marginTop: '10px',
                      display: 'block',
                      filter: 'invert(1)',
                      opacity: 0.5,
                    }}
                  />
                  <div>
                    <Typography as="h5" variant="h6">
                      Статистика обучения
                    </Typography>
                    <Typography>
                      В этом блоке будет отображаться статистика вашего обучения
                      на платформе
                    </Typography>
                  </div>
                </Stack>
              </CardContent>
            </Card>

            <PDFDownloadLink
              document={<CertificateFile cert={certificate} />}
              fileName={certificate?.company?.inn}
            >
              {({ blob, url, loading, error }) =>
                certificate && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    size="large"
                    startIcon={<TaskOutlinedIcon />}
                  >
                    Скачать сертификат об обучении
                  </Button>
                )
              }
            </PDFDownloadLink>

            <Typography sx={{ fontWeight: 'bold', opacity: 0.6 }}>
              Контакты АНО «Санкт-Петербургский центр поддержки экспорта»
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <EmailOutlinedIcon />
              <Typography as="a" href="mailto:info@spbexport.ru">
                {settings?.contactEmail}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <LocalPhoneOutlinedIcon />
              <Typography as="a" href={`tel:${settings?.contactPhone}`}>
                {settings?.contactPhone}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <LocationOnOutlinedIcon />
              <Typography>{settings?.address}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <AccessTimeOutlinedIcon />
              <Typography>Мы на связи по будням с 09:00 до 17:00.</Typography>
            </Stack>
          </div>
        )}
      </Sticky>
    </StickyContainer>
  );
};
