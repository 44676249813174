import axios from 'axios';
import { env } from '../utils/env';

export const getAllSettings = async () => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/settings/get`,
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    throw error;
  }
};
