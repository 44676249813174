import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { RutubeVideoPlayer } from '../components/VideoPlayer/RuTubeVideoPlayer';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { getOneCourseById } from '../api/CourseApi';
import { getOneCourseMaterialById } from '../api/MaterialApi';
import { URLS } from '../utils/URLS';

export const SingleMaterial = () => {
  const [material, setMaterial] = useState({});
  const [course, setCourse] = useState({});
  const { id, courseId } = useParams();
  const navigate = useNavigate();
  const CourseGetOneWithResultApi = useApi(getOneCourseById);
  const MaterialGetOneApi = useApi(getOneCourseMaterialById);

  useEffect(() => {
    MaterialGetOneApi.sendRequest({ materialId: id, relations: ['test'] }).then(
      (result) => setMaterial(result),
    );
  }, [id]);

  useEffect(() => {
    if (courseId) {
      CourseGetOneWithResultApi.sendRequest({
        courseId,
        relations: ['materials'],
      }).then((result) => setCourse(result));
    }
  }, [courseId]);

  const nextMaterial = (currentMaterialId) => {
    const finCurrentIndex = course?.materials?.findIndex(
      (m) => m.id == currentMaterialId,
    );
    return finCurrentIndex + 1 >= course?.materials?.length
      ? false
      : course?.materials[finCurrentIndex + 1] &&
          course?.materials[finCurrentIndex + 1]?.id;
  };

  return (
    <MainLayout>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3 }}>
            <Link underline="hover" color="inherit" href={URLS?.DASHBOARD}>
              Главная
            </Link>
            {course?.id ? (
              <Link
                underline="hover"
                color="inherit"
                href={URLS.ALL_COURSES + '/' + course?.id}
              >
                {course?.title}
              </Link>
            ) : (
              <Link underline="hover" color="inherit" href={URLS.ALL_MATERIALS}>
                Видео материалы
              </Link>
            )}
            <Typography color="text.primary">{material?.title}</Typography>
          </Breadcrumbs>
          <Typography as="h1" variant="h4" sx={{ mt: 2, mb: 4 }}>
            {material?.title}
          </Typography>

          <RutubeVideoPlayer url={material?.rutubeVideo} />
          <Typography
            as="p"
            variant="p"
            dangerouslySetInnerHTML={{ __html: material?.description }}
          ></Typography>
          {material?.test?.id && (
            <Button
              variant="contained"
              size="large"
              sx={{ mt: 2 }}
              onClick={() =>
                course?.id
                  ? navigate(
                      URLS?.ALL_COURSES +
                        '/' +
                        course?.id +
                        '/' +
                        material?.id +
                        '/' +
                        material?.test?.id,
                    )
                  : navigate('/single-test/' + material?.test?.id)
              }
            >
              Продолжить обучение
            </Button>
          )}
        </Grid>
        {course?.id && (
          <Grid item xs={4}>
            <Typography as="h3" variant="h6" sx={{ pt: 3 }}>
              Другие материалы курса
            </Typography>
            {course?.materials?.map((m) => (
              <Box
                onClick={() =>
                  navigate(
                    course?.id
                      ? URLS.ALL_COURSES + '/' + course?.id + '/' + m?.id
                      : URLS.ALL_MATERIALS + '/' + m?.id,
                  )
                }
              >
                <Stack
                  key={m?.id}
                  direction="row"
                  spacing={2}
                  sx={{
                    cursor: 'pointer',
                    borderBottom: 'solid 1px #eee',
                    py: 2,
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '200px',
                      height: '90px',
                      background: '#f1f1f1',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      flex: 1,
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <img
                      src={m?.imageUrl || '/no-image.png'}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div style={{ flex: 2 }}>
                    <Typography>{m?.title}</Typography>
                    <Typography variant="caption">{m?.speaker}</Typography>
                  </div>
                </Stack>
              </Box>
            ))}
          </Grid>
        )}
      </Grid>
    </MainLayout>
  );
};
