// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoPlayer_Player__rSZZg {
  height: 450px;
}

@media only screen and (max-width: 480px) {
  .VideoPlayer_Player__rSZZg {
    height: 200px;
    overflow: hidden;
  }
}
@media only screen and (max-width: 768px) {
  .VideoPlayer_Player__rSZZg {
    height: 250px;
    overflow: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/VideoPlayer/VideoPlayer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI;IACI,aAAA;IACA,gBAAA;EACN;AACF;AAEA;EACI;IACI,aAAA;IACA,gBAAA;EAAN;AACF","sourcesContent":[".Player {\n    height: 450px\n}\n\n@media only screen and (max-width: 480px){\n    .Player {\n        height: 200px;\n        overflow: hidden;\n    }\n}\n\n@media only screen and (max-width: 768px){\n    .Player {\n        height: 250px;\n        overflow: hidden;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Player": `VideoPlayer_Player__rSZZg`
};
export default ___CSS_LOADER_EXPORT___;
