import React, { useState, useEffect } from 'react';
import { SignLayout } from '../components/Layout/SignLayout';
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { URLS } from '../utils/URLS';
import { useApi } from '../hooks/useApi';
import { Auth } from '../api/AuthApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { useCookies } from 'react-cookie';
import { add } from 'date-fns';

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const [customer, setCustomer] = useState({
    email: '',
    password: '',
  });
  const [disabled, setDisabled] = useState(true);

  const AuthApi = useApi(Auth);
  const dispatch = useDispatch();
  const AuthHandler = async () => {
    try {
      const result = await AuthApi.sendRequest(customer);
      if (typeof result == 'string') {
        setCookie('jwt', result, {
          expires: add(new Date(), { seconds: 86400 }),
        });
      }
    } catch (error) {
      dispatch(setAlert({ text: error?.response?.data?.message, status: 409 }));
    }
  };
  useEffect(() => {
    const { email, password } = customer;
    if (!email || !password) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [customer]);
  return (
    <SignLayout title="Авторизация">
      <TextField
        label="Ваш email"
        fullWidth
        sx={{ mt: 3 }}
        required={true}
        value={customer?.email}
        onChange={({ target: { value } }) =>
          setCustomer({ ...customer, email: value })
        }
      />
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={customer?.password}
        onChange={({ target: { value } }) =>
          setCustomer({ ...customer, password: value })
        }
        label="Ваш пароль"
        sx={{ mt: 2 }}
        required={true}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        disabled={disabled}
        color="primary"
        variant="contained"
        sx={{ mt: 3 }}
        fullWidth
        size="large"
        onClick={() => AuthHandler()}
      >
        Войти в ситему
      </Button>
      <Divider sx={{ my: 3 }} />
      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          variant="text"
          fullWidth
          href={URLS.REGISTRATION}
        >
          Регистрация
        </Button>
        <Button
          color="primary"
          variant="text"
          fullWidth
          href={URLS.FORGOT_PASSWORD}
        >
          Восстановить доступ
        </Button>
      </Stack>
    </SignLayout>
  );
};
