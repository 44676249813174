export const URLS = {
  DASHBOARD: '/dashboard',
  ALL_COURSES: '/education',
  SINGLE_COURSE: '/education/:id',
  ALL_MATERIALS: '/materials',
  SINGLE_MATERIAL: '/materials/:id',
  COURSE_MATERIAL: '/education/:courseId/:id',
  ALL_TESTS: '/tests',
  SINGLE_TEST: '/single-test/:id',
  SINGLE_TEST_QUESTION: '/single-test/:testId/:questionId',
  SINGLE_TEST_RESULT: '/single-test/:testId/result/:resultId',
  COURSE_MATERIAL_TEST: '/education/:courseId/:materialId/:id',
  COURSE_MATERIAL_TEST_QUESTION:
    '/education/:courseId/:materialId/:testId/:questionId',
  COURSE_MATERIAL_TEST_RESULT:
    '/test-result/:courseId/:materialId/:testId/result/:resultId',
  LOGIN: '/',
  REGISTRATION: '/registration',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_EMAIL: '/confirm',
  CONFIRM: '/confirm/:uuid',
  PROFILE: '/profile',
};
