import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { usePalette } from 'react-palette';
import { CircularProgressWithLabel } from '../UI/CircularWithValueLabel';
import styles from './CourseItem.module.scss';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/URLS';
import { useCallback } from 'react';
export const CourseItem = ({ course }) => {
  const { data, loading, error } = usePalette(course?.imageUrl);
  const navigate = useNavigate();

  return (
    <Card
      className={styles?.CourseItem}
      variant="outlined"
      onClick={() => navigate(URLS.ALL_COURSES + '/' + course?.id)}
    >
      <CardContent
        sx={{
          height: '100%',
        }}
      >
        <CircularProgressWithLabel
          value={course?.progress}
          color="primary"
          sx={{
            position: 'absolute',
            top: '15px',
            left: '15px',
            zIndex: '1',
          }}
        />
        <img
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
          }}
          src={course?.imageUrl}
        />
        <div
          style={{
            width: '100%',
            height: '50%',
            background: `linear-gradient(to top, ${data.darkVibrant} 30%, transparent)`,
            position: 'absolute',
            bottom: '0',
            left: 0,
          }}
        ></div>
        <Stack
          height="100%"
          justifyContent="end"
          sx={{ position: 'relative', zIndex: 1 }}
        >
          <Typography
            sx={{ color: '#fff', fontWeight: 'bold', lineHeight: '1.2', mb: 1 }}
          >
            {course?.title}
          </Typography>
          <Typography variant="caption" sx={{ color: '#fff' }}>
            {course?.speaker}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
