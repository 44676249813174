import React, { useEffect, useRef, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import Typography from '@mui/material/Typography';
import { Button, Grid, IconButton, Stack } from '@mui/material';

import { CourseItem } from '../components/CourseItem/CourseItem';
import { SlickSlider } from '../components/SlickSlider/SlickSlider';
import { MaterialItem } from '../components/MaterialItem/MaterialItem';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { TestItem } from '../components/TestItem/TestItem';

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { DefaultSidebar } from '../components/DefaultSidebar/DefaultSidebar';
import { useApi } from '../hooks/useApi';
import { getAllCourses } from '../api/CourseApi';
import { getAllSingleMaterials } from '../api/MaterialApi';
import { getSingleTests } from '../api/TestApi';
import { useSettings } from '../hooks/useSettings';
import { URLS } from '../utils/URLS';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export const Dashboard = () => {
  const [courses, setCourses] = useState([]);
  const [materials, setMaterilas] = useState([]);
  const [tests, setTests] = useState([]);
  const settings = useSettings();
  const [goToSlideCourses, setGoToSlideCourses] = useState(0);
  const [slideCoursesIndex, setSlideCoursesIndex] = useState({
    next: 1,
    current: 0,
  });
  const CourseGetAllWithResultApi = useApi(getAllCourses);
  const GetMaterialsApi = useApi(getAllSingleMaterials);
  const GetSingleTestsApi = useApi(getSingleTests);

  const [goToSlideMaterials, setGoToSlideMaterials] = useState(0);
  const [slideMaterialsIndex, setSlideMaterialsIndex] = useState({
    next: 1,
    current: 0,
  });

  const [goToSlideTests, setGoToSlideTests] = useState(0);
  const [slideTestsIndex, setSlideTestsIndex] = useState({
    next: 1,
    current: 0,
  });

  const [sliderWidth, setSliderWidth] = useState(0);
  const mainBlock = useRef(null);
  useEffect(() => {
    setSliderWidth(mainBlock.current ? mainBlock.current.offsetWidth : 0);
  }, [mainBlock.current]);

  useEffect(() => {
    CourseGetAllWithResultApi.sendRequest({
      limit: 9,
      offset: 0,
      relations: ['materials'],
    }).then((result) => setCourses(result[0]));
    GetMaterialsApi.sendRequest({ limit: 9, offset: 0, relations: [] }).then(
      (result) => setMaterilas(result[0]),
    );
    GetSingleTestsApi.sendRequest({
      limit: 9,
      offset: 0,
      relations: ['questions'],
    }).then((result) => setTests(result[0]));
  }, []);

  const navigate = useNavigate();

  return (
    <MainLayout
      hidden={{ tests: !!tests?.length, materials: !!materials?.length }}
    >
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={8} ref={mainBlock}>
          <Typography as="h1" variant="h4" fontWeight="bold">
            {settings?.mainH1Begin}
          </Typography>
          <Typography sx={{ mt: 2, width: '80%' }}>
            {settings?.mainTextBegin}
          </Typography>

          <div
            style={{
              marginTop: '50px',
              width: sliderWidth,
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ mb: 2, mt: 4, width: '100%', pr: 2 }}
              justifyContent="space-between"
            >
              <Typography as="h2" variant="h5" fontWeight="bold">
                Курсы
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  startIcon={<SchoolOutlinedIcon />}
                  onClick={() => navigate(URLS.ALL_COURSES)}
                >
                  Смотреть все
                </Button>
                <IconButton
                  color="primary"
                  onClick={() =>
                    setGoToSlideCourses(slideCoursesIndex.next - 1)
                  }
                >
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setGoToSlideCourses(slideCoursesIndex.next + 1);
                  }}
                >
                  <ArrowForwardIosOutlinedIcon />
                </IconButton>
              </Stack>
            </Stack>
            <SlickSlider
              goToSlide={goToSlideCourses}
              setIndex={(data) => setSlideCoursesIndex(data)}
            >
              {courses?.map((course) => (
                <CourseItem key={course?.id} course={course} />
              ))}
            </SlickSlider>
          </div>

          {!!materials?.length && (
            <div style={{ marginTop: '60px', width: sliderWidth }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mb: 2, mt: 4, width: '100%', pr: 2 }}
                justifyContent="space-between"
              >
                <Typography as="h2" variant="h5" fontWeight="bold">
                  Видео материалы
                </Typography>

                {materials?.length > 3 && (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      startIcon={<SlowMotionVideoOutlinedIcon />}
                      onClick={() => navigate(URLS.ALL_MATERIALS)}
                    >
                      Смотреть все
                    </Button>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        setGoToSlideMaterials(slideMaterialsIndex.next - 1)
                      }
                    >
                      <ArrowBackIosNewOutlinedIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setGoToSlideMaterials(slideMaterialsIndex.next + 1);
                      }}
                    >
                      <ArrowForwardIosOutlinedIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>

              {materials?.length < 3 ? (
                <Stack direction="row" spacing={2}>
                  {materials?.map((m) => (
                    <MaterialItem key={m?.id} material={m} />
                  ))}
                </Stack>
              ) : (
                <SlickSlider
                  goToSlide={goToSlideMaterials}
                  setIndex={(data) => setSlideMaterialsIndex(data)}
                >
                  {materials?.map((m) => (
                    <MaterialItem key={m?.id} material={m} />
                  ))}
                </SlickSlider>
              )}
            </div>
          )}
          {!!tests?.length && (
            <div style={{ marginTop: '60px', width: sliderWidth }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mb: 5, mt: 4, width: '100%', pr: 2 }}
                justifyContent="space-between"
              >
                <Typography as="h2" variant="h5" fontWeight="bold">
                  Тестовые материалы
                </Typography>

                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    startIcon={<CheckBoxOutlinedIcon />}
                    onClick={() => navigate(URLS.ALL_TESTS)}
                  >
                    Смотреть все
                  </Button>
                  <IconButton
                    color="primary"
                    onClick={() => setGoToSlideTests(slideTestsIndex.next - 1)}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setGoToSlideTests(slideTestsIndex.next + 1);
                    }}
                  >
                    <ArrowForwardIosOutlinedIcon />
                  </IconButton>
                </Stack>
              </Stack>
              <SlickSlider
                goToSlide={goToSlideTests}
                setIndex={(data) => setSlideTestsIndex(data)}
              >
                {tests?.map((test) => (
                  <TestItem key={test?.id} test={test} />
                ))}
              </SlickSlider>
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          <DefaultSidebar settings={settings} />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
