import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import { add, format } from 'date-fns';

// Create styles
// Register Font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});
Font.register({
  family: 'RobotoBold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});

// Create style with font-family
const s = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '22px',
  },
  flex: {
    display: 'flex',
    // justifyContent: 'space-between',
    marginTop: 'auto',
    width: '100%',
  },
  footerBlock: {
    display: 'block',
    border: 'solid 10px red',
  },
  logo: {
    width: '230px',
  },
  certBG: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '35%',
    // zIndex: -1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '32px',
    fontFamily: 'RobotoBold',
    marginBottom: '30px',
  },
  expire: {
    marginTop: '50px',
  },
  customText: {
    marginTop: '30px',
  },

  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: '40px',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 10,
  },
});
const statusMapper = {
  finished: 'Пройден',
  complete: 'Пройден',
  success: 'Просмотрен',
  started: 'Начат',
  failed: 'Не пройден',
  viewed: 'Просмотрен',
};
// Create Document Component
export const CertificateFile = ({ cert }) => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    setServices(
      cert?.courses?.map((c) => ({
        ...c?.course?.service,
        created: c.created,
      })),
    );
  }, [cert]);
  return (
    <Document language="ru">
      <Page size="A4" style={s.page}>
        <Image src="cert-logos.png" style={s.logo} />
        <View style={s.flex}>
          <Text style={s.title}>Сертификат</Text>
          <Text as="p" size="m">
            Присваивается компании: {cert?.company?.title}
          </Text>
          <Text as="h2" size="xl" style={s.customText}>
            За успешное прохождение программы на образовательной площадке АНО
            «Санкт-Петербургский центр поддержки экспорта»
          </Text>

          <Text style={s.expire}>Срок действия: 1 год.</Text>
        </View>
        <View style={s.flex}>
          <Text style={s.footerBlock}>
            АНО "Санкт-Петербургский центр поддержки экспорта"
          </Text>
        </View>
      </Page>
      <Page size="A4" style={s.page}>
        <View>
          <Text style={s.title}>Приложение</Text>
          <Text as="p" size="m">
            Приложение №1 "Информация о пройденых курсах и тестах компании -
            {cert?.company?.title}"
          </Text>
        </View>

        <Text as="p" size="m" style={{ marginTop: '30px' }}>
          Информация об обучении
        </Text>
        <View style={s.table}>
          <View style={s.tableRow}>
            <View style={s.tableCol}>
              <Text style={s.tableCell}>Название</Text>
            </View>
            <View style={s.tableCol}>
              <Text style={s.tableCell}>Тип</Text>
            </View>
            <View style={s.tableCol}>
              <Text style={s.tableCell}>Пользователь</Text>
            </View>
            <View style={s.tableCol}>
              <Text style={s.tableCell}>Результат</Text>
            </View>
            <View style={s.tableCol}>
              <Text style={s.tableCell}>Дата</Text>
            </View>
          </View>
          {cert?.tests?.map(
            (t) =>
              t?.test?.title && (
                <View style={s.tableRow} key={t.id}>
                  <View style={s.tableCol}>
                    <Text style={s.tableCell}>{t?.test?.title}</Text>
                  </View>
                  <View style={s.tableCol}>
                    <Text style={s.tableCell}>Тест</Text>
                  </View>
                  <View style={s.tableCol}>
                    <Text style={s.tableCell}>
                      {t?.customer?.firstName && t?.customer?.lastName
                        ? t?.customer?.firstName + ' ' + t?.customer?.lastName
                        : t?.customer?.email}
                    </Text>
                  </View>
                  <View style={s.tableCol}>
                    <Text style={s.tableCell}>{statusMapper[t?.status]}</Text>
                  </View>
                  <View style={s.tableCol}>
                    <Text style={s.tableCell}>
                      {t?.created && format(new Date(t?.created), 'dd.MM.yyyy')}
                    </Text>
                  </View>
                </View>
              ),
          )}
          {cert?.courses?.length &&
            cert?.courses?.map((t) => (
              <View style={s.tableRow} key={t.id}>
                <View style={s.tableCol}>
                  <Text style={s.tableCell}>{t?.course?.title}</Text>
                </View>
                <View style={s.tableCol}>
                  <Text style={s.tableCell}>Курс</Text>
                </View>
                <View style={s.tableCol}>
                  <Text style={s.tableCell}>
                    {t?.customer?.firstName && t?.customer?.lastName
                      ? t?.customer?.firstName + ' ' + t?.customer?.lastName
                      : t?.customer?.email}
                  </Text>
                </View>
                <View style={s.tableCol}>
                  <Text style={s.tableCell}>{statusMapper[t?.status]}</Text>
                </View>
                <View style={s.tableCol}>
                  <Text style={s.tableCell}>
                    {t?.created && format(new Date(t?.created), 'dd.MM.yyyy')}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
};
