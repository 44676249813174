import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export const SlickSlider = ({
  children,
  goToSlide,
  setIndex,
  settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  },
}) => {
  const [slider, setSlider] = useState(null);
  useEffect(() => {
    if (slider) {
      slider.slickGoTo(goToSlide);
    }
  }, [goToSlide]);
  return (
    <div style={{ display: 'block', position: 'relative', width: '100%' }}>
      <Slider
        ref={(slider) => setSlider(slider)}
        beforeChange={(current, next) => setIndex({ next, current })}
        {...settings}
      >
        {children}
      </Slider>
    </div>
  );
};
