import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { getOneCourseById } from '../api/CourseApi';
import { MaterialItem } from '../components/MaterialItem/MaterialItem';
import { SlickSlider } from '../components/SlickSlider/SlickSlider';
import { URLS } from '../utils/URLS';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
export const SingleCourse = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [goToSlideMaterials, setGoToSlideMaterials] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);
  const mainBlock = useRef(null);
  useEffect(() => {
    setSliderWidth(mainBlock.current ? mainBlock.current.offsetWidth : 0);
  }, [mainBlock.current]);

  const [slideMaterialsIndex, setSlideMaterialsIndex] = useState({
    next: 1,
    current: 0,
  });

  const CourseGetOneWithResultApi = useApi(getOneCourseById);
  useEffect(() => {
    if (id) {
      CourseGetOneWithResultApi.sendRequest({
        courseId: id,
        relations: ['materials'],
      }).then((result) => setCourse(result));
    }
  }, [id]);
  const navigate = useNavigate();
  return (
    <MainLayout>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={7}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href={URLS.DASHBOARD}>
              Главная
            </Link>
            <Link underline="hover" color="inherit" href={URLS.ALL_COURSES}>
              Курсы
            </Link>
            <Typography color="text.primary">{course?.title}</Typography>
          </Breadcrumbs>
          <Typography as="h1" variant="h4">
            {course?.title}
          </Typography>
          <Typography as="p" variant="p">
            {course?.speaker || 'Неизвестный спикер'}
          </Typography>

          <Typography
            dangerouslySetInnerHTML={{ __html: course?.description }}
          ></Typography>
          <Card variant="outlined" sx={{ background: '#f1f1f1' }}>
            <CardContent>
              <Typography variant="h5" as="p">
                О тестировании
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: course?.aboutTesting }}
              ></Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Typography as="h5" variant="h6">
            Прогресс курса
          </Typography>
          <LinearProgress
            variant="determinate"
            value={course?.progress || 0}
            sx={{ mt: 3 }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            size="large"
            onClick={() =>
              navigate(
                '/education/' + course?.id + '/' + course?.materials[0]?.id,
              )
            }
          >
            {course?.progress == 0
              ? 'Пройти курс'
              : course?.progress < 100
              ? 'Продолжить прохождение курса'
              : 'Пройти курс снова'}
          </Button>
          {course?.progress >= 100 && (
            <Button
              variant="outlined"
              color="error"
              sx={{ mt: 2 }}
              fullWidth
              size="large"
              startIcon={<TaskOutlinedIcon />}
            >
              Скачать сертификат
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} ref={mainBlock}>
          <Typography as="h2" variant="h5" sx={{ mb: 4, mt: 5 }}>
            Материалы курса
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {course?.materials?.map((material) => (
          <Grid item xs={3}>
            <MaterialItem courseId={course?.id} material={material} />
          </Grid>
        ))}
      </Grid>
    </MainLayout>
  );
};
