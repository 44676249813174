import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { getOneTestById } from '../../api/TestApi';
import { TestPreview } from '../../components/TestPreview/TestPreview';
import { useApi } from '../../hooks/useApi';
import { getCusomerPermissions } from '../../api/UserApi';

export const SingleTestPage = () => {
  const [test, setTest] = useState({});
  const testApi = useApi(getOneTestById);
  const [cookies, setCookie] = useCookies(['jwt']);
  const [permissions, setPermissions] = useState([]);

  const { testId, id, courseId, materialId } = useParams();
  useEffect(() => {
    if (id) {
      testApi
        .sendRequest({
          testId: id,
          relations: ['questions', 'questions.answers'],
        })
        .then((result) => setTest(result));
    }
  }, []);
  return (
    <>
      {test && test?.questions?.length && (
        <TestPreview
          hideMenu={true}
          description={test?.description}
          title={test?.title}
          actionText="Старт"
          test={test}
          actionUrl={
            courseId && materialId
              ? `/education/${courseId}/${materialId}/${test.id}/${test?.questions[0].id}`
              : `/single-test/${test.id}/${test?.questions[0].id}`
          }
        />
      )}
    </>
  );
};
