import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import { Pagination } from '@mui/material';

export const useDataList = (axiosMethod, limit = 15, offset = 0) => {
  const [dataList, setDataList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const DataApi = useApi(axiosMethod);

  useEffect(() => {
    sendRequest();
  }, []);

  const sendRequest = async (l = limit, o = offset) => {
    setLoading(true);
    const result = await DataApi.sendRequest(l, o);
    setDataList(result[0]);
    setCount(+result[1]);
    setLoading(false);
  };
  const pagination = (
    <Pagination
      sx={{ mt: 3 }}
      count={Math.ceil(count / limit)}
      variant="outlined"
      shape="rounded"
      onChange={(e, page) => sendRequest(limit, limit * page)}
    />
  );
  return { sendRequest, dataList, count, loading, pagination };
};
