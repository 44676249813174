import { Button, Typography } from '@mui/material';
import React from 'react';
import { URLS } from '../../utils/URLS';
import { useNavigate } from 'react-router-dom';

export const TestItem = ({ test }) => {
  const navigate = useNavigate();

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Typography
          as="p"
          variant="h6"
          sx={{ fontWeight: 'bold', lineHeight: 1.2 }}
        >
          {test?.title}
        </Typography>
        <Typography variant="caption">
          Вопросов: {test?.questions?.length} шт.
        </Typography>
      </div>
      <Button
        variant="outlined"
        sx={{ mt: 2 }}
        onClick={() => navigate('/single-test/' + test?.id)}
      >
        Открыть тест
      </Button>
    </div>
  );
};
