import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import { getAllSettings } from '../api/SettingsApi';

export const useSettings = () => {
  const [settings, setSettings] = useState({});
  const SettingsApi = useApi(getAllSettings);
  useEffect(() => {
    SettingsApi.sendRequest().then((result) => {
      const sett = {};
      for (const s of result) {
        sett[s.key] = s.value;
      }
      setSettings(sett);
    });
  }, []);
  return settings;
};
