import React, { useEffect, useState } from 'react';
import { SignLayout } from '../components/Layout/SignLayout';
import { Button, CircularProgress, Typography } from '@mui/material';
import { URLS } from '../utils/URLS';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { confirm } from '../api/UserApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';

export const Confirm = () => {
  const { uuid } = useParams();
  const confirmApi = useApi(confirm);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (uuid) {
      confirmApi
        .sendRequest(uuid)
        .then((result) => {
          dispatch(
            setAlert({ text: 'Ваш аккаунт успешно активирован!', status: 200 }),
          );
          navigate(URLS.LOGIN);
        })
        .catch((error) => {
          setError(true);
          dispatch(setAlert({ text: error.message, status: 500 }));
        });
    }
  }, [uuid]);
  return (
    <SignLayout title="Подтверждение регистрации">
      {confirmApi.loading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <Typography>
            При подтверждении почты произошла ошибка, возмоно вы уже подтвердили
            email
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            href={URLS.LOGIN}
            sx={{ mt: 3 }}
          >
            Авторизация
          </Button>
        </>
      ) : (
        <>
          <Typography>
            Вы успешно зарегистрированы! Авторизуйстесь чтобы начать обучение.
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            href={URLS.LOGIN}
            sx={{ mt: 3 }}
          >
            Авторизация
          </Button>
        </>
      )}
    </SignLayout>
  );
};
