// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionPage_QuestionsList__Aswer__sh9re label {
  margin-bottom: 25px !important;
}
.QuestionPage_QuestionsList__Aswer__sh9re label:last-child {
  margin-bottom: 0 !important;
}
.QuestionPage_QuestionsList__Sidebar__GFaZp {
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
}
.QuestionPage_QuestionsList__Pagination__94Uy7 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: auto !important;
}
.QuestionPage_QuestionsList__Informer__KcDPy {
  display: block;
  margin-bottom: 15px;
}
.QuestionPage_QuestionsList__ListBox__Eu3He {
  border-radius: 8px !important;
  max-height: 500px;
  margin-bottom: auto;
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .QuestionPage_QuestionsList__ListBox__Eu3He {
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestionPage/QuestionPage.module.scss"],"names":[],"mappings":"AAEQ;EACI,8BAAA;AADZ;AAEY;EACI,2BAAA;AAAhB;AAII;EACI,YAAA;EACA,wBAAA;EACA,iCAAA;AAFR;AAII;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,2BAAA;AAFR;AAII;EACG,cAAA;EACC,mBAAA;AAFR;AAII;EACI,6BAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAFR;;AAMA;EAEQ;IACI,mBAAA;EAJV;AACF","sourcesContent":[".QuestionsList{\n    &__Aswer {\n        label {\n            margin-bottom: 25px !important;\n            &:last-child {\n                margin-bottom: 0 !important;\n            }\n        }\n    }\n    &__Sidebar {\n        height: 100%;\n        display: flex !important;\n        flex-direction: column !important;\n    }\n    &__Pagination {\n        display: flex;\n        justify-content: space-between;\n        margin-bottom: 15px;\n        margin-top: auto !important;\n    }\n    &__Informer {\n       display: block;\n        margin-bottom: 15px;\n    }\n    &__ListBox {\n        border-radius: 8px !important;\n        max-height: 500px;\n        margin-bottom: auto;\n        margin-top: 40px;\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .QuestionsList {\n        &__ListBox {\n            margin-bottom: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QuestionsList__Aswer": `QuestionPage_QuestionsList__Aswer__sh9re`,
	"QuestionsList__Sidebar": `QuestionPage_QuestionsList__Sidebar__GFaZp`,
	"QuestionsList__Pagination": `QuestionPage_QuestionsList__Pagination__94Uy7`,
	"QuestionsList__Informer": `QuestionPage_QuestionsList__Informer__KcDPy`,
	"QuestionsList__ListBox": `QuestionPage_QuestionsList__ListBox__Eu3He`
};
export default ___CSS_LOADER_EXPORT___;
