import axios from 'axios';
import { env } from '../utils/env';
import Cookies from 'js-cookie';

export const getAllCourses = async ({
  limit = 25,
  offset = 0,
  relations = ['materials'],
}) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/course/get-frontend`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit,
        offset,
        relations,
      },
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getStartedCourses = async ({
  limit = 25,
  offset = 0,
  relations = [],
  status = [],
  excludeStatus = [],
}) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/course/get-started`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit,
        offset,
        relations,
        status,
        excludeStatus,
      },
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneCourseById = async ({ courseId, relations = [] }) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/course/get-frontend/${courseId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations: relations,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createCourseResult = async ({
  course,
  material,
  test,
  status,
  result,
}) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/course/edit-result`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        courseSnapshot: course,
        materialSnapshot: material,
        testSnapshot: test,
        status,
        result,
      },
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const getCourseStatus = async ({ course }) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/course/get-result`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: course?.id
        ? {
            courseSnapshot: {
              id: course.id,
            },
          }
        : {},
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};
