// import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { getCertificate } from '../../api/CertificateApi';
// import { getCourseStatus } from '../../api/CourseApi';
import { useApi } from '../../hooks/useApi';
// import { useSiteChecker } from '../../hooks/useSiteChecker';

// import { CertificatePDF } from '../CertificatePDF/CertificatePDF';
// import { CertItemSmall } from '../CertItem/CerItemSmall';

// import { FileCert } from '../File/FileCert';
import styles from './TestResult.module.scss';
import { getTestResult } from '../../api/TestApi';

import { useCookies } from 'react-cookie';

import { getCertificate } from '../../api/UserApi';
import { Button, Grid, Typography } from '@mui/material';
import { MainLayout } from '../../components/Layout/MainLayout';
import { DefaultSidebar } from '../../components/DefaultSidebar/DefaultSidebar';
import { useSettings } from '../../hooks/useSettings';
export const TestResult = ({
  title,
  description,
  success,
  successActionText,
  successActionUrl,
  badActionText,
  badActionUrl,
  course,
  test,
  nextMaterial,
}) => {
  const { testId } = useParams();

  const [testResult, setTestResult] = useState({});
  const getTestResultApi = useApi(getTestResult);

  const settings = useSettings();
  useEffect(() => {
    getTestResultApi
      .sendRequest({ testId })
      .then((result) => setTestResult(result));
  }, []);
  const [cookies, setCookie] = useCookies(['jwt']);
  return (
    <MainLayout>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={6}>
          <Typography as="h1" variant="h5">
            {testResult?.resultTitle}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: testResult?.resultDescription,
            }}
          ></div>

          <Button
            color="primary"
            variant="contained"
            sx={{ mt: 4 }}
            className={styles.TestResult__ActionButton}
            href={testResult?.nextLinkUrl}
          >
            {testResult?.nextLinkText || 'Продолжить'}
          </Button>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <DefaultSidebar settings={settings} />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
