// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseItem_CourseItem__MKwrc {
  border-radius: 15px !important;
  height: 350px;
  width: 275px;
  cursor: pointer;
  position: relative;
  transition: 0.1s;
}
.CourseItem_CourseItem__MKwrc:hover {
  transform: scale(0.97);
  transition: 0.1s;
}`, "",{"version":3,"sources":["webpack://./src/components/CourseItem/CourseItem.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AAAI;EACI,sBAAA;EACA,gBAAA;AAER","sourcesContent":[".CourseItem {\n    border-radius: 15px !important;\n    height: 350px;\n    width: 275px;\n    cursor: pointer;\n    position: relative;\n    transition: 0.1s;\n    &:hover {\n        transform: scale(0.97);\n        transition: 0.1s;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CourseItem": `CourseItem_CourseItem__MKwrc`
};
export default ___CSS_LOADER_EXPORT___;
