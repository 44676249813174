import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SignLayout } from '../components/Layout/SignLayout';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { URLS } from '../utils/URLS';
import { useApi } from '../hooks/useApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../api/UserApi';
export const Registration = () => {
  const [showPassword, setShowPassword] = useState(false);
  const registrationApi = useApi(createUser);
  const [customer, setCustomer] = useState({
    email: '',
    firstName: '',
    lastName: '',
    passwordAgain: '',
    password: '',
    companyInn: '',
  });
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const { email, firstName, lastName, passwordAgain, password, companyInn } =
      customer;
    console.log({
      email,
      firstName,
      lastName,
      passwordAgain,
      password,
      companyInn,
    });
    if (
      !email ||
      !firstName ||
      !lastName ||
      !passwordAgain ||
      !password ||
      !companyInn
    ) {
      setDisabled(true);
    } else if (password !== passwordAgain) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [customer]);
  const registrationHandler = async () => {
    try {
      await registrationApi.sendRequest(customer);
      navigate(URLS.CONFIRM_EMAIL);
    } catch (error) {
      dispatch(
        setAlert({ text: 'Произошла ошибка при регистрации', status: 500 }),
      );
    }
  };
  return (
    <SignLayout title="Регистрация">
      <TextField
        label="Ваше имя"
        fullWidth
        sx={{ mt: 3 }}
        required={true}
        value={customer?.firstName}
        onChange={({ target: { value } }) =>
          setCustomer({ ...customer, firstName: value })
        }
      />
      <TextField
        label="Ваше фамилия"
        fullWidth
        sx={{ mt: 3 }}
        required={true}
        value={customer?.lastName}
        onChange={({ target: { value } }) =>
          setCustomer({ ...customer, lastName: value })
        }
      />
      <TextField
        label="ИНН организации"
        fullWidth
        sx={{ mt: 3 }}
        required={true}
        value={customer?.companyInn}
        onChange={({ target: { value } }) =>
          setCustomer({ ...customer, companyInn: value })
        }
      />
      <TextField
        label="Ваш email"
        fullWidth
        sx={{ mt: 3 }}
        required={true}
        value={customer?.email}
        onChange={({ target: { value } }) =>
          setCustomer({ ...customer, email: value })
        }
      />
      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-password">
          Ваш пароль
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          label="Ваш пароль"
          fullWidth
          required={true}
          value={customer?.password}
          onChange={({ target: { value } }) =>
            setCustomer({ ...customer, password: value })
          }
          error={
            customer?.password &&
            customer?.passwordAgain &&
            customer?.password !== customer?.passwordAgain &&
            'Пароли должны совпадать'
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {customer?.password &&
          customer?.passwordAgain &&
          customer?.password !== customer?.passwordAgain && (
            <FormHelperText id="filled-weight-helper-text" error={true}>
              Пароли должны совпадать
            </FormHelperText>
          )}
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-password-2">
          Ваш пароль еще раз
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-2"
          type={showPassword ? 'text' : 'password'}
          label="Ваш пароль еще раз"
          required={true}
          error={
            customer?.password &&
            customer?.passwordAgain &&
            customer?.password !== customer?.passwordAgain
          }
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          value={customer?.passwordAgain}
          onChange={({ target: { value } }) =>
            setCustomer({ ...customer, passwordAgain: value })
          }
        />
        {customer?.password &&
          customer?.passwordAgain &&
          customer?.password !== customer?.passwordAgain && (
            <FormHelperText id="filled-weight-helper-text" error={true}>
              Пароли должны совпадать
            </FormHelperText>
          )}
      </FormControl>
      <Button
        color="primary"
        variant="contained"
        sx={{ mt: 3 }}
        fullWidth
        size="large"
        disabled={disabled}
        onClick={() => registrationHandler()}
      >
        Создать аккаунт
      </Button>
      <Divider sx={{ my: 3 }} />
      <Stack direction="row" spacing={2}>
        <Button color="primary" variant="text" fullWidth href={URLS.LOGIN}>
          Авторизация
        </Button>
        <Button
          color="primary"
          variant="text"
          fullWidth
          href={URLS.FORGOT_PASSWORD}
        >
          Восстановить доступ
        </Button>
      </Stack>
    </SignLayout>
  );
};
