import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AlertsList } from '../AlertsList/AlertsList';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/URLS';
import { Helmet } from 'react-helmet';

export const SignLayout = ({ children, title }) => {
  const alerts = useSelector((state) => state.alerts);
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies.jwt) {
      navigate(URLS.DASHBOARD);
    }
  }, [cookies]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Образовательная платформа </title>

        <link
          rel="icon"
          href="https://static.tildacdn.com/tild6466-3666-4132-b032-343939313264/favicon.ico"
          type="image/x-icon"
        />
        <meta
          name="description"
          content="Комплексная поддержка экспортной деятельности малого и среднего бизнеса Петербурга. Широкий спектр услуг от консультаций экспертов до участия в зарубежных выставках. Бесплатно или на условиях софинансирования."
        />
        <meta property="og:url" content="https://track.spbexport.ru" />
        <meta property="og:title" content="Образовательный трек" />
        <meta
          property="og:description"
          content="Комплексная поддержка экспортной деятельности малого и среднего бизнеса Петербурга. Широкий спектр услуг от консультаций экспертов до участия в зарубежных выставках. Бесплатно или на условиях софинансирования."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://static.tildacdn.com/tild3731-6130-4733-b835-646537373732/-/resize/504x/6.jpeg"
        />
      </Helmet>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', py: 5 }}
        spacing={2}
      >
        <AlertsList alerts={alerts} />
        <Grid item xs={4}>
          <Typography as="h1" variant="h5" sx={{ mb: 4, fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Stack direction="row">
            <div>
              <img src="/logo1.png" style={{ width: '90px' }} />
            </div>
            <div>
              <img src="/logo2.png" style={{ width: '110px' }} />
            </div>
            <div>
              <img src="/logo3.png" style={{ width: '80px' }} />
            </div>
            <div>
              <img src="/logo4.png" style={{ width: '70px' }} />
            </div>
            <div>
              <img src="/logo5.png" style={{ width: '70px' }} />
            </div>
          </Stack>

          {children}
        </Grid>
      </Grid>
    </>
  );
};
