// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertsList_AlertsList__AT40\\+ {
  position: fixed;
  right: 15px;
  bottom: 0;
  width: 100%;
  max-width: 350px;
  z-index: 9999;
}
.AlertsList_AlertsList__Item__rbcpp {
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/AlertsList/AlertsList.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAAI;EACI,mBAAA;EACA,kDAAA;AAER","sourcesContent":[".AlertsList {\n    position: fixed;\n    right: 15px;\n    bottom: 0;\n    width: 100%;\n    max-width: 350px;\n    z-index: 9999;\n    &__Item {\n        margin-bottom: 15px;\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlertsList": `AlertsList_AlertsList__AT40+`,
	"AlertsList__Item": `AlertsList_AlertsList__Item__rbcpp`
};
export default ___CSS_LOADER_EXPORT___;
