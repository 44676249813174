import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useApi } from '../hooks/useApi';
import { changePassword, createUser, getMe } from '../api/UserApi';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';
export const Profile = () => {
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [form, setForm] = useState({});
  const [chengePass, setChengePass] = useState(false);
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const GetMeApi = useApi(getMe);
  const UpdateApi = useApi(createUser);
  const ChangePasswordApi = useApi(changePassword);

  useEffect(() => {
    GetMeApi.sendRequest().then((result) => {
      console.log({ result });
      setForm(result);
    });
  }, []);
  const dispatch = useDispatch();
  return (
    <MainLayout>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography as="h1" variant="h5" sx={{ mt: 5 }}>
            {GetMeApi.data?.firstName && GetMeApi.data?.lastName
              ? GetMeApi.data?.firstName + ' ' + GetMeApi.data?.lastName
              : GetMeApi.data?.email}
          </Typography>
          <Typography as="p" variant="text" sx={{ mb: 3 }}>
            Компания - {GetMeApi.data?.company?.inn}
          </Typography>

          {chengePass ? (
            <>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Старый пароль
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPasswordOld ? 'text' : 'password'}
                    value={oldPass}
                    disabled={ChangePasswordApi.loading}
                    onChange={({ target: { value } }) => setOldPass(value)}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPasswordOld(!showPasswordOld)}
                          edge="end"
                        >
                          {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Старый пароль"
                  />
                </FormControl>

                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Новый пароль
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPasswordNew ? 'text' : 'password'}
                    value={newPass}
                    onChange={({ target: { value } }) => setNewPass(value)}
                    fullWidth
                    disabled={ChangePasswordApi.loading}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPasswordNew(!showPasswordNew)}
                          edge="end"
                        >
                          {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Новый пароль"
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  color="primary"
                  disabled={!newPass || !oldPass || ChangePasswordApi.loading}
                  onClick={() =>
                    ChangePasswordApi.sendRequest({
                      oldPassword: oldPass,
                      newPassword: newPass,
                    }).then((result) => {
                      if (result) {
                        dispatch(
                          setAlert({
                            text: 'Пароль успешно обновлен',
                            status: 200,
                          }),
                        );
                      } else {
                        dispatch(
                          setAlert({
                            text: 'При смене пароля произошла ошибка, попробуйте еще раз и проверьте введенные данные',
                            status: 400,
                          }),
                        );
                      }
                    })
                  }
                >
                  Сохранить
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  color="inherit"
                  onClick={() => {
                    setOldPass('');
                    setNewPass('');
                    setChengePass(false);
                  }}
                >
                  Отменить
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Ваше имя
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    value={form?.firstName}
                    onChange={({ target: { value } }) =>
                      setForm({ ...form, firstName: value })
                    }
                    fullWidth
                    disabled={UpdateApi?.loading}
                    startAdornment={
                      <PortraitOutlinedIcon sx={{ mr: 1, opacity: 0.5 }} />
                    }
                    label="Ваше имя"
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Ваша фамилия
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    value={form?.lastName}
                    onChange={({ target: { value } }) =>
                      setForm({ ...form, lastName: value })
                    }
                    fullWidth
                    disabled={UpdateApi?.loading}
                    startAdornment={
                      <SwitchAccountOutlinedIcon sx={{ mr: 1, opacity: 0.5 }} />
                    }
                    label="Ваша фамилия"
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Ваш номер телефона
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    value={form?.phone}
                    onChange={({ target: { value } }) =>
                      setForm({ ...form, phone: value })
                    }
                    fullWidth
                    disabled={UpdateApi?.loading}
                    startAdornment={
                      <PhoneEnabledOutlinedIcon sx={{ mr: 1, opacity: 0.5 }} />
                    }
                    label="Ваш номер телефона"
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Ваш email
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    value={form?.email}
                    onChange={({ target: { value } }) =>
                      setForm({ ...form, email: value })
                    }
                    fullWidth
                    disabled={UpdateApi?.loading}
                    startAdornment={
                      <EmailOutlinedIcon sx={{ mr: 1, opacity: 0.5 }} />
                    }
                    label="Ваш email"
                  />
                </FormControl>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 3, mr: 2 }}
                disabled={UpdateApi?.loading}
                onClick={() =>
                  UpdateApi.sendRequest(form).then((result) =>
                    dispatch(
                      setAlert({
                        text: 'Профиль успешно обновлен',
                        status: 200,
                      }),
                    ),
                  )
                }
              >
                Сохранить профиль
              </Button>
              <Button
                variant="text"
                sx={{ mt: 3 }}
                onClick={() => setChengePass(true)}
              >
                Сменить пароль
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
};
