import logo from './logo.svg';
import './App.css';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { ruRU as dataGridRuRU } from '@mui/x-data-grid';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { ruRU } from '@mui/x-date-pickers/locales';
import { NotFound } from './pages/NotFound';

import { URLS } from './utils/URLS.js';
import { Dashboard } from './pages/Dashboard';
import { Courses } from './pages/Courses';
import { Materials } from './pages/Materials';

import { Login } from './pages/Login';
import { Registration } from './pages/Registration';
import { ForgotPassword } from './pages/ForgotPassword';
import { Confirm } from './pages/Confirm';
import { SingleCourse } from './pages/SingleCourse';
import { SingleMaterial } from './pages/SingleMaterial';
import { Profile } from './pages/Profile';
import { SingleTestPage } from './pages/SingleTestPage/SingleTestPage.js';
import { QuestionPage } from './pages/QuestionPage/QuestionPage.js';
import { Tests } from './pages/Tests.js';
import { TestResult } from './pages/TestResult/TestResult.js';
// import { TestPage } from './pages/TestPage/TestPage.js';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#0174de',
        light: '#468fd2',
        dark: '#0166c4',
        contrastText: '#fff',
      },
      secondary: {
        main: '#dd1222',
        light: '#d53440',
        dark: '#a80c18',
        contrastText: '#fff',
      },
    },
  },
  ruRU,
  dataGridRuRU,
  coreRuRU,
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route exact path={URLS?.DASHBOARD} element={<Dashboard />} />
          <Route exact path={URLS?.ALL_COURSES} element={<Courses />} />
          <Route exact path={URLS?.SINGLE_COURSE} element={<SingleCourse />} />
          <Route exact path={URLS?.ALL_MATERIALS} element={<Materials />} />
          <Route
            exact
            path={URLS?.SINGLE_MATERIAL}
            element={<SingleMaterial />}
          />
          <Route
            exact
            path={URLS?.COURSE_MATERIAL}
            element={<SingleMaterial />}
          />
          <Route exact path={URLS?.ALL_TESTS} element={<Tests />} />
          <Route exact path={URLS?.SINGLE_TEST} element={<SingleTestPage />} />

          <Route
            exact
            path={URLS?.SINGLE_TEST_RESULT}
            element={<TestResult />}
          />

          <Route
            exact
            path={URLS?.COURSE_MATERIAL_TEST_RESULT}
            element={<TestResult />}
          />
          <Route
            exact
            path={URLS?.SINGLE_TEST_QUESTION}
            element={<QuestionPage />}
          />
          <Route
            exact
            path={URLS?.COURSE_MATERIAL_TEST_QUESTION}
            element={<QuestionPage />}
          />
          <Route
            exact
            path={URLS?.COURSE_MATERIAL_TEST}
            element={<SingleTestPage />}
          />
          <Route exact path={URLS?.LOGIN} element={<Login />} />
          <Route exact path={URLS?.REGISTRATION} element={<Registration />} />
          <Route exact path={URLS?.CONFIRM_EMAIL} element={<Confirm />} />
          <Route exact path={URLS?.CONFIRM} element={<Confirm />} />
          <Route exact path={URLS?.PROFILE} element={<Profile />} />
          <Route
            exact
            path={URLS?.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
