import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  getOneTestById,
  getTestResult,
  setQuestionResult,
} from '../../api/TestApi';

import styles from './QuestionPage.module.scss';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import { MainLayout } from '../../components/Layout/MainLayout';
export const QuestionPage = () => {
  const { courseId, materialId, testId, questionId } = useParams();
  const [test, setTest] = useState({});
  const [question, setQuestion] = useState({});

  const [testNavigation, setTestNavigation] = useState({});
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const testApi = useApi(getOneTestById);
  const setQuestionResultApi = useApi(setQuestionResult);
  const getTestResultApi = useApi(getTestResult);

  useEffect(() => {
    testApi
      .sendRequest({
        testId,
        relations: ['questions', 'questions.answers'],
      })
      .then((result) => {
        setTest(result);
        setQuestion(result?.questions?.find((q) => q.id == questionId));
        const currentQuestionIndex = result?.questions?.findIndex(
          (q) => q.id == questionId,
        );
        setTestNavigation({
          currentId: result?.questions[currentQuestionIndex]?.id,
          nextId: result?.questions[currentQuestionIndex + 1]
            ? result?.questions[currentQuestionIndex + 1]?.id
            : null,
          prevId: result?.questions[currentQuestionIndex - 1]
            ? result?.questions[currentQuestionIndex - 1]?.id
            : result?.questions[currentQuestionIndex - 1],
        });
      });
  }, []);

  const setQuestionResultHandler = async () => {
    const testResult = await getTestResultApi.sendRequest({ testId });
    const { nextLink } = await setQuestionResultApi.sendRequest({
      test: {
        id: testId,
      },
      material: {
        id: materialId,
      },
      course: {
        id: courseId,
      },
      question: {
        id: questionId,
      },
      answers: selectedAnswers?.map((a) => ({ id: a })),
      testResult: {
        id: testResult.id,
      },
    });
    window.location = nextLink;
  };

  return (
    <MainLayout>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            as="h1"
            variant="h5"
            sx={{ mt: 5 }}
            dangerouslySetInnerHTML={{ __html: test?.title }}
          ></Typography>
          <Card sx={{ background: '#F5F7FB', mt: 4 }}>
            <CardContent>
              <Typography as="h3" variant="h6">
                {question?.question}
              </Typography>

              {question?.answers?.length && (
                <>
                  {question?.answers?.filter((a) => a.isRight)?.length > 1 ? (
                    <>
                      {question?.answers?.map((answer) => (
                        <FormControlLabel
                          sx={{ width: '100%' }}
                          control={
                            <Checkbox
                              checked={selectedAnswers?.some(
                                (i) => i == answer.id,
                              )}
                              onChange={({ target: { checked } }) => {
                                const newset = new Set(selectedAnswers);
                                if (checked) {
                                  newset.add(answer.id);
                                } else {
                                  newset.delete(answer.id);
                                }
                                setSelectedAnswers(Array.from(newset));
                              }}
                            />
                          }
                          label={answer.answer}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedAnswers ? selectedAnswers[0] : ''}
                        onChange={(event) =>
                          setSelectedAnswers([event.target.value])
                        }
                      >
                        {question?.answers?.map((answer) => (
                          <FormControlLabel
                            value={answer?.id}
                            control={<Radio />}
                            label={answer?.answer}
                          />
                        ))}
                      </RadioGroup>
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={3}></Grid>
        <Grid xs={3}>
          <div
            className={styles.QuestionsList__Sidebar}
            style={{ marginTop: '50px' }}
          >
            {test?.questions?.length && (
              <List className={styles.QuestionsList__ListBox}>
                {test?.questions?.map((q, i) => (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        (window.location = courseId
                          ? `/education/${courseId}/${materialId}/${testId}/${q.id}`
                          : `/single-test/${testId}/${q.id}`)
                      }
                    >
                      <ListItemText primary={q.question} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}

            <LinearProgress
              width="100%"
              variant="determinate"
              sx={{ my: 4 }}
              value={Math.round(
                100 /
                  (test?.questions?.length /
                    test?.questions?.slice(
                      0,
                      test?.questions?.findIndex((q) => q.id == question?.id),
                    )?.length),
              )}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={selectedAnswers?.length == 0}
              onClick={() => setQuestionResultHandler()}
            >
              Продолжить
            </Button>
          </div>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
