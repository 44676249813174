import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { Breadcrumbs, Grid, Link, Stack, Typography } from '@mui/material';
import { DefaultSidebar } from '../components/DefaultSidebar/DefaultSidebar';
import { MaterialItem } from '../components/MaterialItem/MaterialItem';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import { useDataList } from '../hooks/useDataList';
import { getAllSingleMaterials } from '../api/MaterialApi';
import { URLS } from '../utils/URLS';
import { useSettings } from '../hooks/useSettings';

export const Materials = () => {
  const settings = useSettings();
  const { dataList, loading, pagination } = useDataList(
    getAllSingleMaterials,
    15,
    0,
  );
  return (
    <MainLayout>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={8}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href={URLS.DASHBOARD}>
              Главная
            </Link>

            <Typography color="text.primary">Видео материалы</Typography>
          </Breadcrumbs>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 4 }}>
            <SlowMotionVideoOutlinedIcon />
            <Typography as="h1" variant="h5" fontWeight="bold">
              Видео материалы
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            {dataList?.map((material) => (
              <Grid item xs={4} key={material?.id}>
                <MaterialItem material={material} />
              </Grid>
            ))}
          </Grid>
          {pagination}
        </Grid>
        <Grid item xs={4}>
          <DefaultSidebar settings={settings} />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
