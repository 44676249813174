import React, { useEffect, useState } from 'react';
import styles from './VideoPlayer.module.scss';

export const RutubeVideoPlayer = ({ url }) => {
  const [normalUrl, setNormalUrl] = useState('');
  useEffect(() => {
    if (url) {
      const splitUrl = url?.split('/');
      if (splitUrl?.length) {
        setNormalUrl(
          `https://rutube.ru/play/embed/${splitUrl[splitUrl?.length - 2]}`,
        );
      }
    }
  }, [url]);
  return (
    <div
      className={styles.Player}
      style={{ borderRadius: '12px', overflow: 'hidden' }}
    >
      <iframe
        width="100%"
        height="100%"
        title="RuTube video player"
        frameborder="0"
        src={normalUrl}
        allow="clipboard-write; autoplay"
      ></iframe>
    </div>
  );
};
