import axios from 'axios';
import Cookies from 'js-cookie';
import { env } from '../utils/env';

export const createUser = async (data) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/customer/edit`,
      data,
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (data) => {
  try {
    const token = Cookies.get('jwt');
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/customer/change-password`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const confirm = async (data) => {
  try {
    // let config = {
    //   method: 'post',
    //   url: `${env.BACKEND_URL}/customer/edit`,
    //   data,
    // };
    // const resultData = await axios(config);
    // return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (data) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/customer/forgot-password`,
      data,
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (hash, data) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/customer/reset-password/${hash}`,
      data,
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const getMe = async () => {
  const token = Cookies.get('jwt');

  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/customer/get-me`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios(config);
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCusomerPermissions = async () => {
  const token = Cookies.get('jwt');
  try {
    if (token) {
      let config = {
        method: 'get',
        url: `${env.BACKEND_URL}/customer/permissions`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const resultData = await axios(config);
      return resultData.data;
    }
    return {};
  } catch (error) {
    throw error;
  }
};
export const getCertificate = async () => {
  const token = Cookies.get('jwt');
  try {
    if (token) {
      let config = {
        method: 'get',
        url: `${env.BACKEND_URL}/customer/get-certificate`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const resultData = await axios(config);
      return resultData.data;
    }
    return {};
  } catch (error) {
    throw error;
  }
};
