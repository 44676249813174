import axios from 'axios';
import Cookies from 'js-cookie';
import { env } from '../utils/env';

export const getAllCourseMaterials = async ({ page, perPage, ids }) => {
  try {
    const token = 'Y3BlLWFkbWluOmNwZS1wYXNzd29yZC0yMDIy'; //|| Cookies.get('accessToken');

    let config = {
      method: 'get',
      url: `${env.API_URL}/material`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page || 1,
        per_page: perPage || 99,
        include: ids || [],
        order: 'asc',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getAllSingleMaterials = async ({ limit, offset, relations }) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/material/get-all-single`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: limit || 15,
        offset: offset || 0,
        relations: relations || [],
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneCourseMaterialById = async ({
  materialId,
  courseId,
  relations = [],
}) => {
  try {
    const token = Cookies.get('accessToken');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/material/get/${materialId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations,
        courseId,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createMaterialResult = async ({
  course,
  material,
  test,
  status,
  result = false,
}) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/material/edit-result`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        courseSnapshot: course,
        materialSnapshot: material,
        testSnapshot: test,
        status,
        result,
      },
    };

    const res = await axios(config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getMaterialsResultCount = async ({ courseId, findBy, status }) => {
  try {
    const token = Cookies.get('jwt');
    const formdata = new FormData();
    formdata.append('courseId', courseId);
    formdata.append('findBy', findBy);
    formdata.append('token', token);
    formdata.append('status', status);

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/materials/get-materials-result-count`,
      data: formdata,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getViewedCoursesIds = async ({ findBy, status }) => {
  try {
    const token = Cookies.get('jwt');
    const formdata = new FormData();
    formdata.append('findBy', findBy);
    formdata.append('token', token);
    formdata.append('status', status);

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/courses/get-viewed`,
      data: formdata,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getMaterialsStatus = async ({ course }) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/material/get-result`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        courseSnapshot: {
          id: course.id,
        },
      },
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};
