import { Button, Typography } from '@mui/material';
import React from 'react';
import styles from './MaterialItem.module.scss';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/URLS';
export const MaterialItem = ({ courseId, material }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.MaterialItem}>
      <div className={styles.MaterialItem__Img}>
        <img
          src={material?.imageUrl || '/no-image.png'}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <div className={styles.MaterialItem__Img__Placeholder}>
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                courseId
                  ? URLS.ALL_COURSES + '/' + courseId + '/' + material?.id
                  : URLS.ALL_MATERIALS + '/' + material?.id,
              )
            }
          >
            Открыть
          </Button>
        </div>
      </div>
      <Typography sx={{ lineHeight: 1.2, fontWeight: 'bold' }}>
        {material?.title}
      </Typography>
      <Typography variant="caption">{material?.speaker}</Typography>
    </div>
  );
};
