import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CircularProgressWithLabel = ({ value, sx, color = 'primary' }) => {
  return (
    <div style={sx}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          color={color}
          variant="determinate"
          value={value}
          thickness={4}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: '#fff' }}
            fontWeight="bold"
          >
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export const CircularWithValueLabel = () => {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10,
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
};
