import React, { useState } from 'react';
import { SignLayout } from '../components/Layout/SignLayout';
import { Button, Divider, Stack, TextField } from '@mui/material';

import { URLS } from '../utils/URLS';

export const ForgotPassword = () => {
  return (
    <SignLayout title="Восстановление пароля">
      <TextField label="Ваш email" fullWidth sx={{ mt: 3 }} />

      <Button
        color="primary"
        variant="contained"
        sx={{ mt: 3 }}
        fullWidth
        size="large"
      >
        Восстановить доступ
      </Button>
      <Divider sx={{ my: 3 }} />
      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          variant="text"
          fullWidth
          href={URLS.REGISTRATION}
        >
          Регистрация
        </Button>
        <Button color="primary" variant="text" fullWidth href={URLS.LOGIN}>
          Авторизация
        </Button>
      </Stack>
    </SignLayout>
  );
};
